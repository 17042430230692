<template>
  <el-popover ref="popoverRef" :popper-class="popoverClassComputed" :placement="placement" :trigger="trigger" :open-delay="openDelay"
    :width="width" :style="{ '--pm-base-menu-popover-width': `${width}px` }" :offset="offset" :visible-arrow="visibleArrow" @show="handlePopoverShow">
    <template slot="reference">
      <slot name="reference">
        <div slot="reference" class="more" @click.stop>...</div>
      </slot>
    </template>
    <slot name="content">
      <div class="operate">
        <div v-permission="item.permission" :class="{ list: true, active: active === item.key }" v-for="(item, index) of opts" :key="index">
          <div class="pm-base-menu-popover-title-box" @click="clickOption(item)">
            <i v-if="item.icon && item.icon.includes('pm-icon')" :class="['pm-iconfont', item.icon]"></i>
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </slot>
  </el-popover>
</template>

<script>
export default {
  props: {
    offset: {
      type: Number,
      default: () => 0,
    },
    width: {
      type: Number,
      default: () => 100,
    },
    opts: {
      type: Array,
      default: () => [],
    },
    trigger: {
      type: String,
      default: () => "click",
    },
    active: {
      type: String | Number,
      default: () => "",
    },
    placement: {
      type: String,
      default: () => "right-start",
    },
    openDelay: {
      type: Number,
      default: () => 0,
    },
    visibleArrow: {
      type: Boolean,
      default: () => false
    },
    popperClass: {
      type: String,
      default: () => ""
    }
  },
  computed: {
    popoverClassComputed() {
      return ['pm-base-menu-popover', this.popperClass].join(" ");
    }
  },
  methods: {
    clickOption(item) {
      this.close();
      this.$emit("clickEvent", item);
    },
    close() {
      // 关闭悬浮框
      this.$refs.popoverRef.doClose();
    },
    handlePopoverShow() {
      this.$emit("show");
    },
    updatePopper() {
      this.$nextTick(() => {
        this.$refs.popoverRef.updatePopper();
      });
    }
  },
};
</script>

<style lang="scss">
.pm-base-menu-popover {
  min-width: var(--pm-base-menu-popover-width, 120px) !important;
  padding: 0 !important;
  border-radius: 12px !important;
  box-shadow: 0px 4px 12px 0px #41454a1a !important;
  overflow: hidden;
}

.pm-base-menu-popover-title-box {
  display: flex;
  align-items: center;
  column-gap: 4px;
}


.more {
  width: 16px;
  height: 16px;
  line-height: 8px;
  text-align: center;
  border-radius: 8px;
  background: rgba(245, 245, 245, 1);
  color: rgba(16, 50, 104, 1);
  transform: rotate(90deg);

  &:hover {
    cursor: pointer;
  }
}

.operate {
  .list {
    font-weight: bold;
    padding: 8px 0 8px 16px;
    border-bottom: 1px solid rgba(24, 110, 255, 0.05);
    font-size: 12px;
    color: #103268;

    &:hover {
      color: var(--pm-main-menu-active-color);
      background: rgba(24, 110, 255, 0.05);
    }

    @include cursorPointer;
  }

  .pm-iconfont {
    font-size: 14px;
  }

  .active {
    color: var(--pm-main-menu-active-color);
    background: rgba(24, 110, 255, 0.05);
  }
}
</style>
