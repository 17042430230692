<template>
  <div class="app-wrapper" :class="classObj">
    <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside"
    ></div>
    <sidebar class="sidebar-container"></sidebar>
    <div :class="{ hasTagsView: needTagsView }" class="main-container" id="appMainBox">
      <app-main></app-main>
    </div>
    <div :class="{ 'right-box': true, 'has-gap': !isMaxHeight }">
      <task-reminder :open1.sync="open1"></task-reminder>
      <notice @changeHeight="handleChangeNoticeHeight" :open.sync="open2"></notice>
      <calendar :open.sync="open3"></calendar>
    </div>
  </div>
</template>

<script>
import {
  Navbar,
  Sidebar,
  AppMain,
  TagsView,
  taskReminder,
  Notice,
  calendar,
} from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";

export default {
  name: "layout",
  data() {
    return {
      open1: true,
      open2: false,
      open3: true,
      isMaxHeight: false,
    };
  },
  components: {
    Navbar,
    Sidebar,
    AppMain,
    TagsView,
    taskReminder,
    Notice,
    calendar,
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      needTagsView: (state) => state.settings.tagsView,
      fixedHeader: (state) => state.settings.fixedHeader,
    }),
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    classObj() {
      return {
        // hideSidebar: !this.sidebar.opened, // 隐藏状态栏
        // openSidebar: this.sidebar.opened,
        // withoutAnimation: this.sidebar.withoutAnimation,
        // mobile: this.device === 'mobile' //移动端处理方案
      };
    },
  },
  created() {
    this.getHeight();
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("CloseSideBar", { withoutAnimation: false });
    },
    getHeight() {
      const winHeight = window.innerHeight;
      // this.winHeight = winHeight - tabs 高度 这段可选
      // console.log('winHeight', this.winHeight)
    },
    handleChangeNoticeHeight(value) {
      this.isMaxHeight = value;
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "src/styles/mixin.scss";
@import "src/styles/variables.scss";
@import "src/styles/utils.scss";
.app-wrapper {
  @include clearfix;
  height: 100%;
  width: 100%;
  padding: 16px 0 16px 16px;
  background: rgba(224, 229, 234, 0.8);
  display: flex;
  justify-content: space-between;
  column-gap: vw(16);
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
  border: 1px solid #000;
}
.right-box {
  width: vw(400);
  flex-shrink: 0;
  overflow-y: hidden;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  // @include hideScrollbar;
}
.has-gap {
  row-gap: vh(16);
}
</style>
