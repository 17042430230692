<template>
  <div
    class="mask"
    :style="{ background: mask ? 'rgba(0, 0, 0, 0.5)' : 'none' }"
  >
    <div
      class="container"
      :style="{
        top: Top + 'px',
        left:
          typeof Left === 'string' && Left.includes('rem') ? Left : Left + 'px',
      }"
    >
      <div class="cont_top">
        <div class="box" v-infinite-scroll>
          <div
            class="box_list"
            v-for="(item, index) in userList"
            :key="item.id"
          >
            <div class="box-title">
              <el-checkbox
                v-model="item.checkAll"
                @change="handleCheckAllChange(item.checkAll, index)"
                >{{ item.name }}</el-checkbox
              >
              <div class="colse" @click="colse(index)" v-if="item.show">
                <svg-icon icon-class="open_icon"></svg-icon>
              </div>
              <div class="colse" @click="colse(index)" v-else>
                <svg-icon icon-class="close_icon"></svg-icon>
              </div>
            </div>
            <div class="box-cont" v-if="item.show">
              <div class="box-item" v-for="val in item.userlist" :key="val.uid">
                <el-checkbox
                  :label="val.name"
                  :key="val.uid"
                  v-model="val.checked"
                  @change="handleCheckedUsersChange(val.checked, index)"
                >
                  <div class="user">
                    <div class="avatar">
                      <img :src="val.headimg" alt />
                    </div>
                    <span>{{ val.name }}</span>
                  </div>
                </el-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="box-right">
          <div class="boxTitle">@人员列表</div>
          <div class="checked-box">
            <div
              class="box-item"
              v-for="(val, index) in checkedUsers"
              :key="val.uid"
            >
              <el-checkbox
                :label="val.label"
                :key="val.label"
                v-model="val.checked"
                @change="selectCheckedUsersChange(val.checked, index)"
              >
                <div class="user">
                  <div class="avatar">
                    <img :src="val.headimg" alt />
                  </div>
                  <span>{{ val.name }}</span>
                </div>
              </el-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="cont_bottom">
        <div class="cont_bottom_left">
          <el-checkbox v-model="checkAll" @change="handleSelectAllClassfiy"
            >所有人</el-checkbox
          >
        </div>
        <div class="cont_bottom_right">
          <el-button round @click.stop="closeMask">取消</el-button>
          <el-button type="primary" @click.stop="submit" round v-debounce
            >确定</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserListByDepartment } from "../../api/user";
export default {
  name: "userListModalBox",
  props: {
    List: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: Boolean,
      default: true,
    },
    Top: {
      type: Number | String,
      default: 0,
    },
    Left: {
      type: Number | String,
      default: 0,
    },
  },
  data() {
    return {
      userList: [],
      checkAll: false,
      checkedUsers: [], //bij
    };
  },
  mounted() {
    getUserListByDepartment({ name: "" }).then((res) => {
      let list = res.data.map((el) => {
        el.show = true;
        return el;
      });
      this.userList = list;
      this.checkeduser(this.List);
    });
  },
  methods: {
    checkeduser(arr) {
      if (arr.length) {
        this.userList.forEach((item) => {
          item.userlist.forEach((el) => {
            arr.forEach((obj) => {
              if (el.uid === obj.uid) {
                el.checked = true;
                this.checkedUsers.push(el);
              }
            });
          });
        });
      }
    },
    //关闭弹框
    closeMask() {
      this.$emit("update:show", false);
      this.handleSelectAllClassfiy(false);
      this.checkAll = false;
    },
    // 一级事件
    handleCheckAllChange(val, index) {
      const { userlist } = this.userList[index];
      userlist.forEach((item, i) => {
        this.$set(item, "checked", val);
      });
      this.checkNotUser();
    },
    //二级事件
    handleCheckedUsersChange(value, index) {
      const subData = this.userList[index].userlist;
      let tickCount = 0;
      const len = subData.length;
      for (let i = 0; i < len; i += 1) {
        if (subData[i].checked === true) {
          tickCount += 1;
          // this.checkedUsers.push(subData[i])
        } else {
          tickCount -= 1;
          // this.checkedUsers.splice(subData[i],1)
        }
      }
      if (tickCount === len) {
        // 二级全勾选  一级勾选
        this.$set(this.userList[index], "checkAll", true);
      } else {
        // 二级未全选  一级不勾选
        this.$set(this.userList[index], "checkAll", false);
      }
      this.checkNotUser();
    },
    // 总的全选
    handleSelectAllClassfiy(val) {
      if (val) {
        for (let i = 0; i < this.userList.length; i += 1) {
          this.userList[i].checkAll = val;
          this.handleCheckAllChange(true, i); // 调用一级change事件
        }
      } else {
        for (let i = 0; i < this.userList.length; i += 1) {
          this.userList[i].checkAll = val;
          this.handleCheckAllChange(false, i); // 调用一级change事件
        }
      }
    },
    //关闭
    colse(index) {
      this.userList[index].show = !this.userList[index].show;
      console.log("关闭");
    },
    // 选中的人员
    checkNotUser() {
      this.checkedUsers = [];
      this.userList.forEach((item) => {
        item.userlist.forEach((items, index) => {
          if (items.checked && this.checkedUsers.indexOf(items) == -1) {
            this.checkedUsers.push(items);
          }
        });
      });
    },
    //选择后的人员列表操作
    selectCheckedUsersChange(val, index) {
      this.checkNotUser();
    },
    submit() {
      this.$emit("addUsers", this.checkedUsers);
      this.closeMask();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/element-form.scss";
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: rgba(0, 0, 0, 0.5);
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    position: relative;
    // top: -300px;
    overflow: hidden;
    // border: 1px solid red;
    width: 320px;
    // padding: 0 16px;;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 12px 0px rgba(65, 69, 74, 0.1);
    .cont_top {
      width: 100%;
      display: flex;
      .box,
      .box-right {
        max-height: 70vh;
      }
      .box,
      .checked-box {
        overflow: auto;
        box-sizing: border-box;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        width: 100%;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .box-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .boxTitle {
          color: rgba(16, 50, 104, 0.6);
          font-size: 12px;
          padding: 12px 0 7px 17px;
          flex-shrink: 0;
        }
        .checked-box {
          flex: 1;
        }
      }
    }
    .cont_bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border: 1px solid #000;
      padding: 12px 16px;
      border-top: 1px solid rgba(16, 50, 104, 0.1);
    }
  }
}
.colse {
  // border: 1px solid #000;
  // padding-left: 25px;
}
.box-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 7px 8px 16px;
  // border: 1px solid #000;
}
.box-cont {
  background: #f4f9ff;
}
.box-item {
  padding: 8px 7px 8px 16px;
  // border: 1px solid #000;
  .user {
    display: flex;
    align-items: center;
    .avatar {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.svg-icon {
  width: 16px;
  height: 16px;
  display: block;
}
::v-deep .el-checkbox {
  // border: 1px solid red;
  display: flex;
  align-items: center;
}
::v-deep .el-checkbox__inner {
  border-radius: 3px;
  width: 16px;
  height: 16px;
}
::v-deep .el-checkbox__label {
  color: #103268;
  font-size: 12px;
  font-weight: 600;
  padding-left: 8px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #103268;
}
::v-deep .el-checkbox__inner::after {
  left: 5px;
}
::v-deep .cont_bottom_right .el-button {
  border: none;
  padding: 0;
  font-size: 10px;
  margin: 0;
  width: 48px;
  height: 24px;
  // line-height: -20px;
  background: linear-gradient(
    90deg,
    rgba(73, 167, 255, 0.1) 0%,
    rgba(71, 119, 255, 0.1) 100%
  );
}
::v-deep .cont_bottom_right .el-button--primary {
  // background: #000;
  background: linear-gradient(90deg, #49a7ff 0%, #4777ff 100%);
  color: #fff;
}
::v-deep .cont_bottom_right .el-button + .el-button {
  margin-left: 16px;
  // background: none;
}
</style>
