<template>
  <div class="menu-wrapper">
    <div class="title-header">
      <CustomElPopover :width="180">
        <template slot="reference">
          <div class="img-box">
            <el-avatar class="headimg avatar-box" :src="headImg" fit="contain"></el-avatar>
          </div>
        </template>
        <template slot="content">
          <div class="myavator-options-wrapper">
            <div class="header-userinfo-box">
              <el-avatar class="headimg" :src="headImg" fit="contain"></el-avatar>
              <div class="user_info">
                <div class="name" :title="userName">{{ userName }}</div>
                <div class="tag" :title="position">
                  <span>{{ position }}</span>
                </div>
              </div>
            </div>
            <div class="operate-list">
              <div class="operate-item" @click="updateHeadImg">
                <i class="pm-iconfont pm-icon-xiugaitouxiang"></i>
                <span>修改头像</span>
              </div>
              <!-- TODO -->
              <!-- <div class="operate-item">修改密码</div> -->
              <div class="operate-item" @click="logout">
                <i class="pm-iconfont pm-icon-tuichudenglu"></i>
                <span>退出登录</span>
              </div>
            </div>
          </div>
        </template>
      </CustomElPopover>
      <span class="title-box" v-if="orgInfo">
        <div class="title" :title="orgInfo.name">{{ orgInfo.name }}</div>
        <div class="sub-title" :title="deptInfo.name">{{ deptInfo.name }}</div>
      </span>
    </div>
    <el-menu mode="vertical" :show-timeout="200" :default-active="defaultActivePath" background-color="#fff"
      text-color="#103268" active-text-color="#103268" unique-opened @open="handleOpen" @close="handleClose"
      id="pm_menu_wrapper" class="pm-side-menu-wrapper">
      <div class="custom-menu-wrapper" id="menu_real_wrapper">
        <div class="self-menus" v-if="selfRoutes && selfRoutes.length">
          <div class="self-menus-inner">
            <router-link v-for="item in selfRoutes" :to="'/' + item.path" :key="item.meta.title">
              <el-menu-item :index="item.path">
                <div class="title-box">
                  <span v-if="item.meta && item.meta.title" class="title">{{
                    item.meta.title
                  }}</span>
                  <span class="text" v-if="item.realCount > 0">{{ item.realCount }}</span>
                </div>
              </el-menu-item>
            </router-link>
          </div>
        </div>

        <div class="infinite-list">
          <sidebar-item v-for="route in dynamicRoutes" :key="route.name" :item="route"
            :base-path="route.path"></sidebar-item>
        </div>
      </div>
    </el-menu>
    <div class="menu-footer" v-if="settinsOptions.length">
      <CustomElPopover :width="200" placement="top-start" :offset="8" :opts="settinsOptions"
        @clickEvent="clickOption($event)">
        <template slot="reference">
          <div class="setting-icon-box">
            <i class="pm-iconfont pm-icon-shezhi"></i>
            <span>设置</span>
          </div>
        </template>
      </CustomElPopover>
      <!-- 聊天室 -->
      <!-- <ChatRoomBtn /> -->
    </div>
    <AvatarCropper :dialogVisible.sync="dialogVisible" @closeAvatarDialog="closeAvatarDialog"></AvatarCropper>
  </div>
</template>

<script>
import router from "@/route/index";
import { mapGetters, mapActions } from "vuex";
import SidebarItem from "./SidebarItem";
import variables from "@/styles/variables.scss";
import {
  MY_ORGANIZATION,
  MY_DEPARTMENT,
  MY_SETTINGS,
} from "@/constants/storageKey";
import CustomElPopover from "@/components/customElPopover/index.vue";
import { DEFAULT_AVATAR } from "@/constants/baseImgPath";
import {
  getHeadImg,
  getUserName,
  getUserInfo,
  getDynamicRoutesMenus,
  getUid,
} from "@/utils/auth";
import { updateUserInfo } from "@/api/user";
import AvatarCropper from "@/components/User/avatarCropper.vue";
import { uploadBigFile } from "@/utils/bigFile";
import { getMenuItemCount } from "@/api/auth";
// import ChatRoomBtn from "./ChatRoomBtn.vue";

export default {
  components: { SidebarItem, CustomElPopover, AvatarCropper },
  data() {
    return {
      myRouter: router,
      dialogVisible: false,
      headImg: "",
      position: "",
      selfRoutes: [],
      dynamicRoutes: []
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
    routes() {
      return router.options.routes.filter(
        (item) => item?.meta?.title !== "mine"
      );
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
    defaultActivePath() {
      return this.$route.path;
    },
    orgInfo() {
      return JSON.parse(sessionStorage.getItem(MY_ORGANIZATION) ?? "{}");
    },
    deptInfo() {
      return JSON.parse(sessionStorage.getItem(MY_DEPARTMENT) ?? "{}");
    },
    userName() {
      return getUserName();
    },
    settingsMenu() {
      return JSON.parse(sessionStorage.getItem(MY_SETTINGS) ?? "[]");
    },
    settinsOptions() {
      const arr = [
        {
          title: "个人信息",
          key: "userInfo",
          icon: "pm-icon-geren",
        },
      ];
      if (this.settingsMenu.includes("architecture")) {
        arr.push({
          title: "组织架构",
          key: "architecture",
          icon: "pm-icon-zuzhijiagou",
        });
      }
      if (this.settingsMenu.includes("permissionRoles")) {
        arr.push({
          title: "自定义角色权限设置",
          key: "permissionRoles",
          icon: "pm-icon-jiaosequanxian",
        });
      }
      if (this.settingsMenu.includes("approvalAuthority")) {
        arr.push({
          title: "审批权限终点设置",
          key: "approvalAuthority",
          icon: "pm-icon-a-16_TY_C_NV_shezhijiaosequanxian",
        });
      }
      return arr;
    },
    uid() {
      return getUid();
    },
  },
  created() {
    this.getStaticMenus();
    this.getDynamicMenus();
  },
  mounted() {
    this.headImg = getHeadImg() || DEFAULT_AVATAR;
    this.position = getUserInfo()?.position || "暂无职位";
    // 检查菜单内容是否撑满当前容器，添加底部阴影
    this.checkHeights();
    window.addEventListener("resize", this.checkHeights);
    this.$eventHub.$on("socket_menu_count_refresh", this.handleRefreshMenu);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkHeights);
    this.$eventHub.$off("socket_menu_count_refresh", this.handleRefreshMenu);
  },
  methods: {
    ...mapActions("permission", ["queryDynamicRoutes"]),
    variables() {
      return variables;
    },
    /**
     * 刷新静态、动态菜单数据
     */
    handleRefreshMenu(payload = null) {
      if (!!payload && !payload?.mine) {
        this.getDynamicMenus(payload);
      } else if (!!payload) {
        this.getStaticMenus(payload);
      } else {
        this.getStaticMenus();
        this.getDynamicMenus();
      }
    },
    /**
     * 获取静态菜单（每个成员都可见的）
     * @param payload 刷新指定菜单 
     */
    getStaticMenus(payload) {
      this.selfRoutes = router.options.routes.filter(
          (item) => item?.meta?.title === "mine"
      )?.[0].children;
      // 指定刷新某个菜单数字
      if (payload) {
        const meta = this.selfRoutes.find(item => item.meta.count === payload.count)?.meta;
        !!meta && this.queryCount(meta, "static");
        return;
      }
      for (let i = 0; i < this.selfRoutes.length; i++) {
        const { did, count, mine } = this.selfRoutes[i].meta;
        if (did && count && mine) {
          this.queryCount(this.selfRoutes[i].meta, "static");
        }
      }
    },
    /**
     * 获取动态菜单
     */
    getDynamicMenus(payload = null) {
      this.dynamicRoutes = router.options.routes.filter(
        (item) => item?.meta?.title !== "mine"
      );
      // 指定刷新某个菜单数字
      if (payload) {
        let meta = null;
        this.dynamicRoutes.forEach(item => {
          if (item.children) {
            item.children.forEach(child => {
              if (child.meta.did === payload.did && child.meta.count === payload.count) {
                meta = child.meta;
                meta && this.queryCount(meta, "dynamic");
              }
            });
          }
        })
        return;
      }
      for (let i = 0; i < this.dynamicRoutes.length; i++) {
        if (this.dynamicRoutes[i].children) {
          for (let j = 0; j < this.dynamicRoutes[i].children.length; j++) {
            const { did, count } = this.dynamicRoutes[i].children[j].meta;
            if (did && count) {
              this.queryCount(
                this.dynamicRoutes[i].children[j].meta,
                "dynamic"
              );
            }
          }
        }
      }
    },
    handleOpen(path) {
      try {
        let routes = getDynamicRoutesMenus();
        // 匹配部门
        const result1 = routes.find(
          (item) =>
            /^\/[0-9]/.test(path) &&
            path.includes(item.did) &&
            item.children &&
            item.children.length > 0
        );
        if (result1) {
          this.$router.push({
            path: `${path}/${result1.children[0].path}`,
          });
          return;
        }
        // 匹配系统内置，如人员管理-人员信息
        const result2 = routes.find((item) => item.path.includes(path));
        if (result2) {
          this.$router.push({
            path: result2.redirect,
          });
          return;
        }
      } finally {
        setTimeout(() => {
          this.checkHeights();
        },300);
      }
    },
    handleClose() {
      setTimeout(() => {
          this.checkHeights();
      },300);
    },
    clickOption(item) {
      this.$router.push({
        name: `pm-${item.key}`,
      });
    },
    closeAvatarDialog(blob) {
      this.dialogVisible = false;
      blob.name = "icon";
      uploadBigFile(blob, (url) => {
        if (url) {
          updateUserInfo({
            id: this.uid,
            headimg: url,
          })
            .then((res) => {
              let headimg = res?.data?.headimg;
              setHeadImg(headimg);
              this.headImg = headimg;
              this.$message.success("头像修改成功");
            })
            .catch((err) => {})
            .finally(() => {
              this.dialogVisible = false;
            });
        }
      });
    },
    updateHeadImg() {
      this.dialogVisible = true;
    },
    logout() {
      this.$confirm("是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sessionStorage.clear();
          localStorage.clear();
          location.reload();
        })
        .catch(() => {});
    },
    checkHeights() {
      const outerContainer = document.getElementById("pm_menu_wrapper");
      const innerContainer = document.getElementById("menu_real_wrapper");
      const classname = "pm-side-menu-wrapper-box-shadow";
      if (innerContainer.offsetHeight >= outerContainer.offsetHeight) {
        outerContainer.classList.add(classname);
      } else {
        outerContainer.classList.remove(classname);
      }
    },
    /**
     * 查询菜单项目数量
     * @param meta 
     * @param type static-修改个人菜单，dynamic-修改部门菜单，默认static
     */
    async queryCount(meta, type = "static") {
      const params = {
        f: meta.count,
        mine: meta.mine,
        did: meta.did
      }
      const res = await getMenuItemCount(params)
      if (type === "static") {
        this.selfRoutes.forEach((item) => {
          if (item.meta.count === meta.count) {
            this.$set(item, "realCount", res.data);
          }
        })
      } else {
        this.$nextTick(() => {
          this.dynamicRoutes.forEach((item) => {
            if (item.children) {
              item.children.forEach((child) => {
                if (child.meta.did === meta.did && child.meta.count === meta.count) {
                  this.$set(child, "realCount", res.data);
                }
              })
            }
          })
        })
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-scrollbar__bar .is-vertical {
  overflow-x: hidden;
}

::v-deep .sidebar-wrapper .el-scrollbar__wrap {
  overflow-x: hidden;
}

.menu-wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .title-header {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 108px;
    overflow: hidden;
    background: linear-gradient(45deg, #e8f3ef, #fff);
    padding: 16px 16px 0 16px;

    .img-box {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      overflow: hidden;
      line-height: 35px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title-box {
      flex: 1;
      margin-top: 2px;

      .title {
        font-size: 16px;
        color: var(--pm-main-text-color);
        @include lineBreak(1);
      }

      .sub-title {
        font-size: 12px;
        color: var(--pm-sub-text-color);
        @include lineBreak(1);
      }
    }
  }
}

.pm-side-menu-wrapper {
  border-right: 0;
  flex: 1;
  @include hideScrollbar;
}

.pm-side-menu-wrapper-box-shadow {
  box-shadow: inset 0 -6px 5px -2px rgba(0, 0, 0, 0.04);
}

.self-menus {
  padding: 12px 8px;

  .el-menu-item {
    padding: 0 !important;
    border-radius: 4px;
  }

  .self-menus-inner {
    width: 100%;
    overflow: hidden;
    position: relative;

    .title-box {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 8px;
      span {
        margin-left: 25px;
      }

      .text {
        width: 20px !important;
        height: 20px !important;
        line-height: 20px !important;
        margin: 0;
        text-align: center;
        transform: scale(0.8);
        background: var(--pm-main-menu-active-color);
        color: #fff;
        border-radius: 100%;
      }
    }

    .is-active {
      color: var(--pm-main-menu-active-color) !important;
    }

    .router-link-exact-active {
      li {
        color: var(--pm-main-menu-active-color) !important;

        &::before {
          content: "";
          display: block;
          width: 3px;
          height: 16px;
          background: var(--pm-main-menu-active-color);
          position: absolute;
          left: 9px;
          top: 9px;
          border-radius: 10px;
        }
      }
    }

    li {
      background: transparent !important;
      font-size: 12px;
      font-weight: 700;
      color: rgba(16, 50, 104, 0.6) !important;
      opacity: 0.8;
      position: relative;
      height: 32px;
      line-height: 32px;

      .is-active {
        color: var(--pm-main-menu-active-color) !important;
      }

      .title {
        margin-left: 32px;
      }

      &:hover {
        cursor: pointer;
        background: linear-gradient(90deg,
            rgba(73, 167, 255, 0.1) 0%,
            rgba(71, 119, 255, 0.1) 100%) !important;
      }
    }

    &::before {
      content: "";
      display: block;
      width: 1px;
      height: calc(100% - 20px);
      background: rgba(13, 104, 253, 0.1);
      position: absolute;
      left: 10px;
      top: 12px;
    }
  }
}

.avatar-box {
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

.menu-footer {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: #fff;
  padding: 0 16px;

  .setting-icon-box {
    i {
      font-size: 16px;
    }

    span {
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss">
.myavator-options-wrapper {
  padding: 12px;

  .header-userinfo-box {
    display: flex;
    column-gap: 8px;
    justify-content: space-between;
    padding: 12px 0 8px 0;
    border-bottom: 1px solid #e6f1f2;

    .headimg {
      flex-shrink: 0;
    }

    .user_info {
      flex: 1;
      font-weight: 700;

      .name {
        color: var(--pm-main-text-color);
        @include lineBreak(1);
      }

      .tag {
        padding: 0 8px;
        height: 22px;
        line-height: 22px;
        border-radius: 12px;
        background: linear-gradient(to right,
            rgba(73, 167, 255, 0.1),
            rgba(71, 119, 255, 0.1));
        color: #0d68fd;
        @include lineBreak(1);
        display: inline-block;
        max-width: 100%;

        span {
          display: block;
          font-size: 12px;
          transform: scale(0.835);
        }

        text-align: center;
      }
    }
  }

  .operate-list {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 2px;

    .operate-item {
      padding: 4px 8px;
      font-weight: bold;
      font-size: 12px;
      color: #103268;
      display: flex;
      align-items: center;
      column-gap: 6px;

      i {
        font-size: 14px;
      }

      &:hover {
        color: var(--pm-main-menu-active-color);
        background: rgba(24, 110, 255, 0.05);
      }

      @include cursorPointer;
    }
  }
}
</style>
